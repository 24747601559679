import { Component } from 'react';
import { Button } from 'antd';

export class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }

  btnClickedHandler() {
   this.props.clicked(this.props.data);
  }

  render() {
    return (
        <Button type={this.props.buttonClass || 'danger'}
                onClick={this.btnClickedHandler}>
          {this.props.buttonText || 'Delete'}
        </Button>
    )
  }
}
