import { BtnCellRenderer } from "../custom-components/ButtonRenderer";
import { DropDownRenderer } from "../custom-components/DropDownRenderer";
import { SubscriptionDropDownRenderer } from "../custom-components/SubscriptionDropDownRenderer";
import { CustomSwitch } from "../custom-components/CustomSwitch";
import {ImageCellRenderer} from "../custom-components/ImageCellRenderer";
import { TaggingDropDownRenderer } from "../custom-components/TaggingDropDownRenderer";

export const gridOption = {
    flex: 1,
    editable: true,
    sortable: true,
    filter: true,
    autoHeight: true,
}

export const frameworkComponents = {
    customSwitch: CustomSwitch,
    btnCellRenderer: BtnCellRenderer,
    dropDownCellRenderer: DropDownRenderer,
    taggingDropDownRenderer: TaggingDropDownRenderer,
    SubscriptionDropDownRenderer: SubscriptionDropDownRenderer,
    imageCellRenderer: ImageCellRenderer,
}

export function dateFormatter(param) {
    return (new Date(param.data[param.colDef.field]).toLocaleString("en-GB"))
}

export const dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        const dateAsString = cellValue.split(',')[0];
        if (dateAsString == null) return -1;
        const dateParts = dateAsString.split('/');
        const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
};

// for adding value to filter course dropdown column
export function courseDropdownTextGetter(param) {
    const course_list = param.data.course
    let data_list = ""
    for (const item of course_list) {
        data_list += item.course_name + ', '
    }
    return data_list
}

// for adding value to filter tagging dropdown column
export function tagDropdownTextGetter(param) {
    const tag_list = param.data.tagging
    let data_list = ""
    for (const item of tag_list) {
        data_list += item.tagging_name + ', '
    }
    return data_list
}

// for adding value getter
export function accessgroupDropdownTextGetter(param) {
    const ag_list = param.data.subscription
    let data_list = ""
    for (const item of ag_list) {
        data_list += item.access_group.name + ', '
    }
    return data_list
}