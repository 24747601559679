import axios from "axios";
import { AuthenticationService } from "./AuthenticationService";

export const ApiService = {
  postRequest,
  getRequest,
  deleteRequest,
  updateRequest,
  getCloudflareVideoDetail,
  uploadFile,
  uploadFileWithBody
}

export function postRequest(url, data) {
  return axios.post(url, data,
    {
      headers: {
        Authorization: AuthenticationService.getHeaders(),
        'Content-Type' :'application/json'
      }
    }
  );
}

export function uploadFile (endPoint, file)  {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      Authorization: AuthenticationService.getHeaders(),
      'content-type': 'multipart/form-data',
    },
  };
  return axios.post(endPoint, formData,config);
}

export async function getRequest(url, param) {
    const tokenSavedTime = AuthenticationService.getTokenSavedTime();
    const tokenSavedTimeDifference = (new Date().getTime() / 1000) - Number(tokenSavedTime);
    // check if the current token saving time is over 1 day
    if (tokenSavedTimeDifference > 86400) {
        // call refresh token API after access token is expired
        const uid = AuthenticationService.getUserID();
        const refreshToken = AuthenticationService.getRefreshToken();
        const formData = {
            userId: Number(uid),
            refreshToken: refreshToken
        };
        const data = await AuthenticationService.refreshTokenApi(formData);
        await AuthenticationService.setToken(data.access_token, data.refresh_token, uid);
    }
    return axios.get(url,
        {
            params: param || '',
            headers: {
                Authorization: AuthenticationService.getHeaders(),
                'Content-Type': 'application/json'
            }
        }
    );
}

export function uploadFileWithBody(endPoint, body) {
  const config = {
    headers: {
      Authorization: AuthenticationService.getHeaders(),
      "content-type": "multipart/form-data",
    },
  };
  return axios.post(endPoint, body, config);
}

export function deleteRequest(url) {
  return axios.delete(url,
    {
      headers: {
        Authorization: AuthenticationService.getHeaders(),
        'Content-Type' :'application/json'
      }
    }
  );
}

export function updateRequest(url, data) {
  return axios.patch(url, data,
    {
      headers: {
        Authorization: AuthenticationService.getHeaders(),
        'Content-Type' :'application/json'
      }
    },
  );
}

export function getCloudflareVideoDetail(url) {
  return axios.get(url,
    {
      headers: {
        Authorization: AuthenticationService.getHeaders(),
        'Content-Type' :'application/json'
      }
    },
  )
}
