import {Component, useState} from 'react';
import { Switch } from "antd";

export class CustomSwitch extends Component {

  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }

  btnClickedHandler(event) {
    this.props.clicked(this.props.data);
  }

  render() {
    return (
        <Switch defaultChecked={this.props?.data[this.props?.param] || ''}
                onChange={this.btnClickedHandler} />
    )
  }
}
