import { Component } from 'react';
import { Select } from 'antd';
const Option = Select.Option;

export class SubscriptionDropDownRenderer extends Component {
  selectedItemIDs = [];

  constructor(props) {
    super(props);
    this.selectedItemIDs = this.props.data[this.props.dropDownData?.selectField]
                            ?.map(item=> item?.access_group?.sku)
                            ?.filter(item => item) || [];
    this.selectedItemIDs = [...new Set(this.selectedItemIDs)];
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }

  btnClickedHandler(event) {
    const data = {
      id: this.props.data.id,
      items: event,
    }
    this.props.clicked(data);
  }

  render() {
    return (
        <Select
            showSearch
            disabled
            removeIcon={''}
            defaultValue={this.selectedItemIDs}
            onChange={this.btnClickedHandler}
            size="default"
            mode={ this.props.dropDownData?.single ? '' : 'multiple'}
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            className="w-100percent mw-250">
          {this.props.dropDownData?.items.map((mode, index) => (
              <Option value={mode.sku} key={index}>
                {mode[this.props.dropDownData?.type]}
              </Option>
          ))}
        </Select>
    )
  }
}
