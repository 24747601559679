import {Component } from 'react';
import {Image} from "antd";

export class ImageCellRenderer extends Component {
  image = '';

  constructor(props) {
    super(props);
    this.image =  (this.props?.data[this.props?.field || '' ] || '');
  }

  render() {
    return (
        this.image
        ? <Image src={this.image}
             width='auto'
             height='80'
             alt={this.props?.data?.name || ''} />
        : <></>
    )
  }
}
