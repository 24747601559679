import { Component } from "react";
import { Select } from "antd";
const Option = Select.Option;

export class TaggingDropDownRenderer extends Component {
  selectedItemIDs = [];
  showID = false;

  constructor(props) {
    super(props);
    this.showID = this.props.dropDownData?.type === "episode_name";
    if (this.props.dropDownData?.single) {
      this.selectedItemIDs =
        +this.props.data[this.props.dropDownData?.selectField] || null;
    } else if (this.props.dropDownData?.selectField === "subscription") {
      this.selectedItemIDs =
        this.props.data[this.props.dropDownData?.selectField]
          ?.map((item) => item?.access_group?.id)
          ?.filter((item) => item) || [];
      this.selectedItemIDs = [...new Set(this.selectedItemIDs)];
    } else {
      this.selectedItemIDs =
        this.props.data[this.props.dropDownData?.selectField]?.map(
          (item) => item.id
        ) || [];
    }
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }

  btnClickedHandler(event) {
    const data = {
      id: this.props.data.id,
      items: event,
    };
    this.props.clicked(data);
  }

  render() {
    return (
      <Select
        showSearch
        defaultValue={this.selectedItemIDs}
        onChange={this.btnClickedHandler}
        size="default"
        mode={this.props.dropDownData?.single ? "" : "multiple"}
        filterOption={(input, option) =>
          option.props.children
            .join("")
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        className="w-100percent mw-250"
      >
        {this.props.dropDownData?.items.map((mode, index) => (
          <Option value={mode.id} key={index}>
            {this.showID ? `(ID:${mode.id})` : ""}{" "}
            {`(${mode['tag_type'].toLowerCase()}) `}{mode[this.props.dropDownData?.type]}
          </Option>
        ))}
      </Select>
    );
  }
}
